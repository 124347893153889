<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
        style="background-color: #f4ae9e"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <div class="w-100">
          <b-col
            sm="4"
            md="4"
            lg="8"
            class="px-xl-5 mx-auto p-lg-8"
            style="padding-bottom: 4rem !important"
          >
            <img src="@/assets/images/logo/logo.png" alt="logo" />
          </b-col>

          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
              {{ $t("loginHeader") }}👋
            </b-card-title>
            <b-card-text class="mb-2">
              {{ $t("loginAltHeader") }}
            </b-card-text>

            <validation-observer ref="loginForm" #default="{ invalid }">
              <b-form class="auth-login-form mt-2" @submit.prevent="login">
                <!-- email -->
                <b-form-group label="Email" label-for="login-email">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      name="login-email"
                      placeholder="kullanici@mail.com"
                    />
                    <small class="text-danger" />
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">{{ $t("Password") }}</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Parola"
                      />

                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>

                    <small class="text-danger" />
                  </validation-provider>
                </b-form-group>
                <div class="my-0" style="float: right">
                  <a style="color: black !important" @click="forgotPass()">{{
                    $t("Parolamı Unuttum")
                  }}</a>
                </div>

                <b-button
                  variant="dark"
                  style="margin-top: 50px"
                  block
                  @click="validationForm()"
                  >{{ $t("Login") }}</b-button
                >
              </b-form>
            </validation-observer>
          </b-col>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/login.png"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      token: "",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    async validationForm() {
      this.$http
        .post("v1/Account/B2BAuthenticate", {
          email: this.userEmail,
          password: this.password,
        })
        .then((data) => {
          const cevap = data.data.data;
          if (cevap.isActive === false) {
            this.$toast({
              component: ToastificationContent,
              position: "bottom-right",
              props: {
                title: this.$t("Hatalı"),
                icon: "DangerIcon",
                variant: "danger",
                text: "Membership Not Active Please Consult the Official!",
              },
            });
            return;
          }
          const uData = data.data.data;

          this.token = uData.jwToken;
          localStorage.setItem("B2BUserToken", uData.jwToken);
          this.selectedFirm(
            uData.companies[0].id,
            uData.companies[0].caption,
            uData.b2BCariName,
            uData.b2BCariCode,
            uData.kanal
          );
          localStorage.setItem(
            "B2BsecilenFirmaKeyi",
            uData.companies[0].companyKey
          );
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "bottom-right",
            props: {
              title: this.$t("Hatalı"),
              icon: "DangerIcon",
              variant: "danger",
              text: error.response.data.Message,
            },
          });
        });
    },
    getAuthentication() {
      return {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      };
    },
    selectedFirm(firmId, firmName, cariName, cariKode, kanal) {
      const req = {
        companyId: firmId,
      };

      this.$http
        .post("v1/Account/SelectCompany", req, this.getAuthentication())
        .then(async (response) => {
          localStorage.setItem("B2BUserToken", response.data.data.jwToken);
          await store.dispatch(
            "signalRStore/addToken",
            response.data.data.jwToken
          );

          const userData = {
            email: response.data.data.email,
            roleId: response.data.data.roleId,
            fullName: response.data.data.fullName,
            id: response.data.data.id,
            roleId: response.data.data.roleId,
            profileImgUrl: response.data.data.profileImgUrl,
            title: response.data.data.title,
            erpStaffNo: response.data.data.erpStaffNo,
            erpUserName: response.data.data.erpUserName,
            firmSelected: `${firmId} - ${firmName}`,
            firstToken: this.token,
            isAdmin: response.data.data.isAdmin,
            isDemo: response.data.data.isDemo,
            isUser: response.data.data.isUser,
            title: response.data.data.title,
            musteriAd: cariName,
            musteriKod: cariKode,
            kanal,
          };
          localStorage.setItem("B2BUserData", JSON.stringify(userData));
          await store.dispatch("signalRStore/addUserAndUserId", userData);
          // Rollere ihtiyaç duyarsam o zaman aktif ederim
          // var response = await this.$http.get(
          //   "v1/UserSetting/GetRoleSettingsId?id=" + userData.roleId
          // );
          // localStorage.setItem("B2BRoleSetting", response.data);
          const menuResponse = await this.$http.get(
            `v1/User/GetMyMenus?roleId=${userData.roleId}`
          );
          const menu = menuResponse.data.data[0].pages[0];
          menu.components = menu.components.sort(
            (a, b) => a.componentId - b.componentId
          );

          localStorage.setItem("B2BUserMenu", JSON.stringify(menu));

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: cariName,
              icon: "CoffeeIcon",
              variant: "success",
              text: this.$t("loginMesaj"),
            },
          });

          this.$router.push({
            name: "tekliftopla",
          });
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.log(error);
        });
    },
    forgotPass() {
      this.$router.push({
        name: "page-forgot-password",
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
